import React, { useEffect, useRef } from "react";

import ScrollAnimation from 'react-animate-on-scroll';

const FullWidthImage = ({ data, image, alt, id, location }) => {

    const scroller = useRef(null)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (typeof document !== 'undefined') {
                if (location.hash) {
                    let elem = document.getElementById(location.hash.slice(1))
                    if (elem) {
                        scroller.current.scrollIntoView()
                    }
                }
            }
        }, 2000);

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);

    }, [location])

    return (
        <ScrollAnimation animateIn='fadeIn' delay={200}>
            <div className="container-full-width-image " id={id} ref={scroller} >
                <img src={image} alt={alt} />
            </div >
        </ScrollAnimation>
    );
}
export default FullWidthImage;
import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const TopParallax = ({ data, name }) => {
    const imageRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const image = imageRef.current;

        if (image) {
            ScrollTrigger.create({
                trigger: image,
                start: "top top",
                end: "+=50%",
                toggleActions: "play none reverse play",
                scrub: true,
                overscroll: true,
                onUpdate: (scrollTrigger) => {
                    // Update y and scale based on scroll position
                    gsap.to(image, {
                        y: scrollTrigger.progress * 50, // Adjust max scroll offset
                        scale: 1 + scrollTrigger.progress * 0.2, // Adjust max zoom factor
                        ease: "power2.out",
                    });
                },
            });
        }
    }, [imageRef]);

    return (
        <div className="container-top-section-top-parallax-services">
            <div className="container-text">
                <h1 className="white ISemiBold fs-1 uppercase pb-1">{name}</h1>
            </div>
            <div className="top-parallax" ref={imageRef}>

                <img alt={data.alt}
                    src={data.image}
                    className="parallax-image"
                    ref={imageRef}
                />
            </div>
        </div>
    );
};

export default TopParallax;